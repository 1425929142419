import styled, { css, keyframes } from "styled-components";

const Section = styled.section`
  margin-bottom: 30px;
`;

const Header = styled.h1`
  font-size: 146px;
  font-weight: 600;

  text-transform: uppercase;

  color: #000;

  margin-bottom: 8px;
`;

const Title = styled.h1`
  font-size: 56px;
  font-weight: 800;

  /* text-transform: uppercase; */

  color: #000;

  margin-bottom: 8px;

  ${props =>
    props.right &&
    css`
      text-align: right;
    `}
`;

const SubTitle = styled.h2`
  font-size: 24px;
  font-weight: 400;

  color: #fca745;

  margin-bottom: 16px;

  ${props =>
    props.right &&
    css`
      text-align: right;
    `}
`;

const Text = styled.p`
  font-size: 20px;

  color: #000;

  line-height: 1.5;

  margin-bottom: 16px;
`;

const SmallText = styled.p`
  font-size: 17px;

  color: #000;

  line-height: 1.25;

  margin-bottom: 16px;
`;

const ListTitle = styled.li`
  /* background-image: url("./images/chevron-right.svg");
  background-repeat: no-repeat;
  background-position: 1.5% 100%; */
  font-size: 18px;

  font-weight: 600;
  /* font-style: italic; */

  color: #000;

  line-height: 1.5;
`;

const ListItem = styled.li`
  font-size: 18px;

  color: #000;

  line-height: 1.5;

  a {
    color: #000;
    /* text-decoration: none; */

    :hover {
      color: #fca745;
    }
  }
`;

const List = styled.ul`
  margin: 0 0 16px 2px;

  ${props =>
    props.right
      ? css`
          ${ListItem} {
            border-right: 6px solid #bdbdbd;

            text-align: right;
            padding-right: 12px;
          }

          ${ListTitle} {
            border-right: 6px solid #fca745;

            text-align: right;
            padding-right: 12px;
          }
        `
      : css`
          ${ListItem} {
            border-left: 6px solid #bdbdbd;

            text-indent: 12px;
          }

          ${ListTitle} {
            border-left: 6px solid #fca745;

            text-indent: 12px;
          }
        `}
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: row;

  margin-top: 24px;

  @media (max-width: 1000px) {
    justify-content: flex-end;
  }
`;

const NavListEntry = styled.li`
  margin-right: 16px;
  color: ${props => (props.selected ? "#000" : "#DBDBDB")};

  font-size: 20px;
  font-weight: 400;

  @media (max-width: 1000px) {
    font-size: 16px;
  }

  :hover {
    color: #000;
    cursor: pointer;
  }
`;

const NameInput = styled.input.attrs({ type: "text", name: "name" })`
  /* background-color: #ececec; */
  display: inline-block;

  font-family: "Barlow", sans-serif;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 8px;

  margin-bottom: 16px;
  font-size: 14px;

  width: ${props => (props.size ? props.size : "200px")};

  ::placeholder {
    color: #000;
    opacity: 0.5;
  }
`;

const EmailInput = styled.input.attrs({ type: "email", name: "email" })`
  /* background-color: #ececec; */
  display: inline-block;

  font-family: "Barlow", sans-serif;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 8px;

  margin-bottom: 16px;
  font-size: 14px;

  width: ${props => (props.size ? props.size : "200px")};

  ::placeholder {
    color: #000;
    opacity: 0.5;
  }
`;

const MultilineInput = styled.textarea.attrs({ name: "message" })`
  /* background-color: #ececec; */
  display: block;

  font-family: "Barlow", sans-serif;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 8px;

  flex: 1;
  height: 256px;
  font-size: 14px;

  ::placeholder {
    color: #000;
    opacity: 0.5;
  }
`;

const ButtonTextAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-38px);
    opacity: 0;
  }

  51% {
    transform: translateY(38px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

const Button = styled.button`
  ${props => props.size && "width: " + props.size};
  height: 38px;

  background: none;
  /* border: none; */
  border: 1px solid #000;
  border-radius: 4px;

  color: #000;

  ${props => props.primary && css`
    background-color: #6abd82;
    color: #fff;
    border: none;
  `}

  font-family: "Barlow", sans-serif;
  font-size: 14px;
  font-weight: 200;

  display: inline-block;

  cursor: pointer;

  padding: 0 16px;

  

  outline: none;

  /* transition: background-color 0.25s ease-in-out, border 0.25s ease-in-out,
    color 0.15s ease-in-out; */

  overflow: hidden;

  :hover {
    /* background-color: #fca745; */
    /* border: 1px solid #fca745; */
    /* color: #fff;

    transition: background-color 0.25s ease-in-out, border 0.25s ease-in-out,
      color 0.15s ease-in-out; */

    p {
      animation: ${ButtonTextAnimation} 0.25s ease-in-out;
    }
  }
`;

const LoadAnim = keyframes`
0% {
  opacity: 0;
  transform: scale(0.99);
}

100% {
  opacity: 1;
  transform: scale(1);
}`;

export {
  Section,
  Title,
  Header,
  SubTitle,
  Text,
  List,
  ListTitle,
  ListItem,
  NavList,
  NavListEntry,
  NameInput,
  EmailInput,
  MultilineInput,
  SmallText,
  Button,
  LoadAnim
};
