import React from "react";
import Styled from "styled-components";

import * as Style from "../ComponentStyles.js";

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 80px 16px 0;
  height: calc(100% - 80px);

  animation: ${Style.LoadAnim} 0.25s ease-in;
`;

const ContactContainer = Styled.div`
  display: flex;
  flex-direction: row;

  max-width: 1440px;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const FormContainer = Styled.section`
  width: 100%;
  margin-bottom: 64px;

  @media (min-width: 1001px) and (max-width: calc(1440px + 31px)) {
    width: calc(50vw - 16px);
    margin-bottom: 0;
  }

  @media (min-width: calc(1440px + 32px)) {
    width: 768px;
    margin-bottom: 0;
  }
`;

const InputContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OtherContainer = Styled.section`
  width: 100%;

  @media (min-width: 1001px) and (max-width: calc(1440px + 31px)) {
    width: calc(50vw - 16px);
  }

  @media (min-width: calc(1440px + 32px)) {
    width: 672px;
  }
`;

const ButtonContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 16px;
`;

const ContactMe = () => {
  document.title = "Contact | Stefan Cauchi";

  return (
    <Container>
      <ContactContainer>
        <FormContainer>
          <Style.Title>Contact Me</Style.Title>
          <Style.SubTitle>
            Drop me a line by using the form below.
          </Style.SubTitle>
          <form name="contact" method="post" netlify>
            <input type="hidden" name="form-name" value="contact" />
            <InputContainer>
              <Style.NameInput placeholder="Your name" size="35%" />
              <Style.EmailInput placeholder="Your email" size="55%" />
            </InputContainer>
            <InputContainer>
              <Style.MultilineInput placeholder="Your message" />
            </InputContainer>
            <ButtonContainer>
              <Style.Button type="submit" size="128px">
                <p>Send</p>
              </Style.Button>
            </ButtonContainer>
          </form>
        </FormContainer>
        <OtherContainer>
          <Style.Title right>Other Platforms</Style.Title>
          <Style.SubTitle right>
            Or get in touch through other platforms.
          </Style.SubTitle>
          <Style.List right>
            <Style.ListItem>
              <a href="mailto:cauchi.stefan@gmail.com">Email me</a>
            </Style.ListItem>
            {/* <Style.ListItem>
              <a href="https://github.com/Reselence?tab=repositories">Github</a>
            </Style.ListItem> */}
            <Style.ListItem>
              <a href="https://www.linkedin.com/in/stefancauchi/">LinkedIn</a>
            </Style.ListItem>
          </Style.List>
        </OtherContainer>
      </ContactContainer>
    </Container>
  );
};

export default ContactMe;
