import React from "react";
import Styled from "styled-components";

import * as Style from "../ComponentStyles.js";

const Container = Styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 16px 0;

  animation: ${Style.LoadAnim} 0.25s ease-in;
`;

const TextContainer = Styled.div`
  width: 100%;
  margin-right: 16px;

  @media (min-width: 1400px) {
    width: 768px;
  }

  z-index: 2;
`;

const ImageContainer = Styled.div`
  width: 50vw;
  margin-left: 16px;

  background: url("/images/portrait2.png") no-repeat;
  background-position: 50% 0;
  background-size: auto 100%;

  display: none;

  @media (min-width: 1000px) {
    display: block;
  }

  @media (min-width: 1440px) {
    width: 640px;
  }

  z-index: 2;
`;

const Section = Styled(Style.Section)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: initial;
  }
`;

const SubSection = Styled.div``;

const AboutAndSkills = () => {
  document.title = "About | Stefan Cauchi";

  return (
    <React.Fragment>
      <Container>
        <TextContainer>
          <Style.Section>
            <Style.Title>About Me</Style.Title>
            <Style.SubTitle>A little about me.</Style.SubTitle>
            <Style.Text>
              Hey! I'm Stefan. I'm primarily a Web Developer, experienced in
              many of the latest and trending web technologies. I graduated
              first class with a Bachelors Degree in Computer Software (HONS)
              back in 2015 and love making responsive, beautiful, and
              intuitively designed web applications. Coming from a Game
              Development background, intuitive interface design is something
              very important to me and is the lifeblood of any web application.
            </Style.Text>
            <Style.Text>
              During my learning process I make it a habit to learn not only the
              programming side of things, but also the design process and flow,
              to ensure that I'm more than capable of handling projects
              singlehandedly as well as knowing what to expect from my
              coworkers. Take a look at my knowledge of current tech and my
              traits below.
            </Style.Text>
          </Style.Section>
          <Section>
            <SubSection>
              <Style.Title>Knowledge</Style.Title>
              <Style.SubTitle>What I know</Style.SubTitle>
              <Style.List>
                <Style.ListTitle>Front End</Style.ListTitle>
                <Style.ListItem>HTML5 + CSS3</Style.ListItem>
                <Style.ListItem>JavaScript</Style.ListItem>
                <Style.ListItem>React</Style.ListItem>
                <Style.ListItem>Jest Unit Testing</Style.ListItem>
                <Style.ListItem>Version Control GIT & SVN</Style.ListItem>
                <Style.ListItem>C# & .NET</Style.ListItem>
              </Style.List>
              <Style.List>
                <Style.ListTitle>Back End</Style.ListTitle>
                <Style.ListItem>ASP.NET</Style.ListItem>
                <Style.ListItem>PHP</Style.ListItem>
                <Style.ListItem>MySQL & Microsoft SQL</Style.ListItem>
              </Style.List>
              <Style.List>
                <Style.ListTitle>Applications</Style.ListTitle>
                <Style.ListItem>Adobe XD</Style.ListItem>
                <Style.ListItem>Adobe Photoshop</Style.ListItem>
                <Style.ListItem>Adobe Illustrator</Style.ListItem>
                <Style.ListItem>3Ds Max</Style.ListItem>
                <Style.ListItem>Unity</Style.ListItem>
              </Style.List>
            </SubSection>
            <SubSection>
              <Style.Title right>Traits</Style.Title>
              <Style.SubTitle right>What I keep in mind</Style.SubTitle>
              <Style.List right>
                <Style.ListTitle>Coding</Style.ListTitle>
                <Style.ListItem>Documentation</Style.ListItem>
                <Style.ListItem>Modularity</Style.ListItem>
                <Style.ListItem>Accessibility</Style.ListItem>
                <Style.ListItem>Responsive Design</Style.ListItem>
              </Style.List>
              <Style.List right>
                <Style.ListTitle>Design</Style.ListTitle>
                <Style.ListItem>UI & UX</Style.ListItem>
                <Style.ListItem>Typography</Style.ListItem>
                <Style.ListItem>Colour Theory</Style.ListItem>
              </Style.List>
            </SubSection>
          </Section>
        </TextContainer>
        <ImageContainer />
      </Container>
    </React.Fragment>
  );
};

export default AboutAndSkills;
