import React from "react";
import Styled, { keyframes } from "styled-components";

import * as Style from "../ComponentStyles.js";

const NotFoundStyle = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const Section = Styled(Style.Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  margin-bottom: 0;
`;

const Text = Styled(Style.Text)`
  width: 50%;

  @media (max-width: 768px) {
    width: 98%;
  }
`;

const Quote = Styled(Text)`
  font-style: italic;
  font-size: 16px;

  ::before {
    content: '"';
  }

  ::after {
    content: '"';
  }
`;

const Anim = keyframes`
    0% {
        transform: rotateZ(-25deg);
    }

    50% {
        transform: rotateZ(25deg) scale(1.2);
    }

    100% {
        transform: rotateZ(-25deg);
    }
`;

const Strong = Styled.p`
  font-weight: bold;
  display: inline-block;
  font-size: 15px;

  animation: ${Anim} 0.1s linear infinite;
`;

const Link = Styled.p`
  color: #000;
  font-size: 14px;
`;

const NotFound = () => {
  document.title = "404 | Stefan Cauchi";

  return (
    <NotFoundStyle>
      <Section>
        <Text>
          I could not find that page... Are you sure you typed/pasted in the
          correct URL?
        </Text>

        <Quote>
          Where the <Strong>heck</Strong> am I and <Strong>what</Strong> is
          this!? <Strong>Helpppppp</Strong>
        </Quote>

        <Text>
          <Link>- you, probably</Link>
        </Text>
      </Section>
    </NotFoundStyle>
  );
};

export default NotFound;
