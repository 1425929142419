import React, { useState } from "react";
import Styled, { css } from "styled-components";

import * as Style from "../ComponentStyles.js";

import { Link } from "react-router-dom";

const Container = Styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 16px 0;

  animation: ${Style.LoadAnim} 0.25s ease-in;
`;

const EntryContainer = Styled(Style.Section)`
  width: 100%;

  @media (min-width: 1440px) {
    width: 1440px;
  }
`;

const Entries = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: 32px;
`;

const Entry = Styled.div`
  @media (min-width: 1000px) {
    ${props =>
      props.tileType === 2
        ? css`
            width: calc(50% - 12px);
            margin-right: 12px;
          `
        : props.tileType === 0
        ? css`
            width: calc(50% - 12px);
            margin-left: 12px;
          `
        : css``}
  }

  width: 100%;

  height: 560px;

  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const EntryImage = Styled.div`
  height: 500px;

  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2),
    0 2px 4px -1px rgba(0, 0, 0, 0.25);

    ${props => css`
      background: url(${props => props.url}) no-repeat;
      background-position: 50%;
    `}

    background-size: 100% auto;
`;

const EntryInfo = Styled.div`
  /* background-color: #f9c96b; */
  height: 50px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;

  padding: 0 16px;
`;

const EntryLeft = Styled.div`
  flex: 1;
`;

const EntryTitle = Styled.h3`
  color: #000;
  font-size: 16px;
  font-weight: 600;
`;

const EntrySubTitle = Styled.h4`
  color: #b7b7b7;
  font-size: 14px;

  margin-top: 8px;
`;

const StyledLink = Styled(Link)`
  width: 100%;

  height: 560px;

  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const tabs = ["Web", "Design", "Games", "3D Modelling", "All"];

const getProjectId = project => {
  return project.title.toLowerCase().replace(new RegExp(" ", "g"), "-");
};

const getScreenshotBasePath = title => {
  return `/images/projects/${title
    .toLowerCase()
    .replace(new RegExp(" ", "g"), "-")}/`;
};

const Projects = props => {
  document.title = "Projects | Stefan Cauchi";

  const [tab, setTab] = useState("Web");

  const handleTabChange = selectedTab => {
    setTab(selectedTab);
  };

  const getProjectsByTab = selectedTab => {
    if (selectedTab === "Web") {
      return props.projects.filter(x => x.types.includes("web"));
    } else if (selectedTab === "Design") {
      return props.projects.filter(x => x.types.includes("design"));
    } else if (selectedTab === "Games") {
      return props.projects.filter(x => x.types.includes("games"));
    } else if (selectedTab === "3D Modelling") {
      return props.projects.filter(x => x.types.includes("3dmodelling"));
    } else {
      return props.projects;
    }
  };

  return (
    <Container>
      <EntryContainer>
        <Style.Title>Projects</Style.Title>
        <Style.SubTitle>What have I worked on?</Style.SubTitle>
        <nav>
          <Style.NavList>
            {tabs.map((el, ind) => (
              <Style.NavListEntry
                key={ind}
                {...tab === el && { selected: true }}
                onClick={() => handleTabChange(el)}
              >
                {el}
              </Style.NavListEntry>
            ))}
          </Style.NavList>
        </nav>
        <Entries>
          {getProjectsByTab(tab).map((cur, ind) => (
            <Entry key={ind} tileType={(ind + 1) % 3}>
              <StyledLink to={"/projects/" + getProjectId(cur)}>
                <EntryImage
                  url={`${getScreenshotBasePath(cur.title)}banner.png`}
                />
              </StyledLink>
              <EntryInfo>
                <EntryLeft>
                  <EntryTitle>
                    {cur.title}, {cur.year}
                  </EntryTitle>
                  <EntrySubTitle>{cur.roles.join(", ")}</EntrySubTitle>
                </EntryLeft>
              </EntryInfo>
            </Entry>
          ))}
        </Entries>
      </EntryContainer>
    </Container>
  );
};

export default Projects;
