import React, { useState } from "react";
import Styled, { css } from "styled-components";

import { Link } from "react-router-dom";

const HeaderStyle = Styled.div`
  width: calc(100% - 32px);
  height: 64px;

  margin: 0 16px 0;

  display: flex;
  flex-direction: row;
  justify-content: center;

  position: fixed;
  top: 0;

  z-index: 3;

  ${props =>
    props.animate === "hide"
      ? css`
          transform: translateY(-65px);
          opacity: 0;
          transition: all 0.4s ease 0s;
        `
      : css`
          transform: translateY(0px);
          opacity: 1;
          transition: all 0.4s ease 0s;
        `}
`;

const Wrapper = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  @media (min-width: 1440px) {
    width: 1440px;
    flex: 0 1 auto;
  }
`;

//////////////
/* OLD LOGO */
//////////////

// const StyledLinkLogo = styled(Link)`
//   font-weight: 200;
//   width: 34px;

//   color: #000;
//   background-color: #fff;

//   border: 1px solid #000;
//   border-top: none;
//   border-bottom-left-radius: 4px;
//   border-bottom-right-radius: 4px;

//   padding: 0 8px 8px;
//   user-select: none;

//   font-size: 34px;

//   height: 50px;
//   transition: background 0.15s ease-in, height 0.15s ease-in;

//   text-decoration: none;

//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;

//   @media (pointer: fine) {
//     :hover {
//       height: 70px;
//       transition: height 0.15s ease-in;
//     }
//   }
// `;

const NewLogo = Styled(Link)`
  @import url("https://fonts.googleapis.com/css?family=Teko&display=swap");
  font-family: "Teko", sans-serif;

  border-radius: 4px;
  font-size: 30px;
  color: #000;
  text-decoration: none;

  margin-top: 24px;
  padding: 8px;
  letter-spacing: 4px;

  background-color: #fff;
`;

const MenuContainer = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 128px;
`;

const Menu = Styled.div`
  ul {
    li {
      text-align: right;
      padding-top: 4px;
    }
  }

  ${props =>
    props.animate
      ? css`
          transform: translateY(0);
          opacity: 1;
          transition: transform 0.24s ease-in, opacity 0.4s ease-in;
        `
      : css`
          transform: translateY(-128px);
          opacity: 0;
          transition: transform 0.24s ease-in, opacity 0.4s ease-in;
        `}
`;

const MenuButton = Styled.div`
  border: 1px solid #000;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  background: #fff url("/images/icons/icon_menu_open.svg") no-repeat;
  background-position: 50% 80%;

  width: 16px;
  height: 50px;
  transition: height 0.15s ease-in;

  padding: 0 8px 8px;
  user-select: none;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  ${props =>
    props.animate
      ? css`
          height: 100px;

          background: url("/images/icons/icon_menu_close.svg") no-repeat;
          background-position: 50% 90%;

          transition: height 0.15s ease-in, background 0.25s ease-in;
        `
      : css`
          @media (pointer: fine) {
            :hover {
              height: 70px;
              background-position: 50% 85%;
              transition: height 0.15s ease-in,
                background-position 0.15s ease-in;
            }
          }
        `}
`;

const StyledLink = Styled(Link)`
  color: #000;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

const links = [
  { title: "Home", href: "/" },
  { title: "About Me", href: "/about" },
  { title: "Projects", href: "/projects" },
  { title: "Contact Me", href: "/contact" }
];

const Header = props => {
  const hasBgUrl = props.bgUrl !== undefined;
  const isLanding = props.landing !== undefined;

  const [showMenu, setShowMenu] = useState(false);

  return (
    <HeaderStyle
      {...isLanding && { landing: 1 }}
      {...hasBgUrl && { backgroundUrl: props.bgUrl }}
      animate={props.animate}
    >
      <Wrapper>
        <NewLogo to="/">
          <p>stefan cauchi</p>
        </NewLogo>
        {/* <MenuContainer>
          <Menu animate={showMenu}>
            <ul>
              {links.map((link, ind) => (
                <li key={ind}>
                  <StyledLink
                    to={link.href}
                    onClick={() => {
                      setShowMenu(false);
                    }}
                  >
                    {link.title}
                  </StyledLink>
                </li>
              ))}
            </ul>
          </Menu>
          <MenuButton
            animate={showMenu}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          />
        </MenuContainer> */}
      </Wrapper>
    </HeaderStyle>
  );
};

export default Header;
