import React, { useEffect, useState } from "react";

import "./reset.css";
import Styled from "styled-components";

import Header from "./components/Header";
import AboutAndSkills from "./components/AboutAndSkills";
import Projects from "./components/Projects";
import ContactMe from "./components/ContactMe";
import Home from "./components/Home";
import Project from "./components/Project";
import NotFound from "./components/NotFound";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const AppStyle = Styled.div`
  background-color: #fff;

  @import url("https://fonts.googleapis.com/css?family=Barlow&display=swap");
  font-family: "Barlow", sans-serif;

  height: 100%;
`;

const Content = Styled.div`
  z-index: 2;
  position: relative;

  height: 100%;
`;

const projects = [
  {
    year: 2019,
    types: ["web", "design"],
    title: "The Uncut Ruby",
    subTitle: "A spanish restaurant site",
    description:
      "A project I worked on in an evening. I set a goal to make something in 3 hours that's simple, has a friendly interface, and that works on a range of screen dimensions. I chose a single colour and ran with it, creating a minimalistic site for a fictional spanish restaurant. Includes home page, menu page, and a page to reserve a table.",
    roles: ["Design", "Coding"],
    techCategories: [
      { title: "Core", technologies: ["React", "JavaScript"] },
      {
        title: "React Modules",
        technologies: ["Styled-Components", "React Router DOM"]
      },
      {
        title: "Applications",
        technologies: ["Adobe Xd", "Adobe Illustrator", "VS Code"]
      },
      { title: "Misc", technologies: ["GIT"] }
    ],
    screenshotUrls: ["home.png", "menu.png", "reserve.png", "mobile.png"]
  },
  {
    year: 2019,
    types: ["web"],
    title: "Ramen Restaurant",
    subTitle: "A site with a focus on the menu and online ordering of ramen",
    description:
      "A single page site for a ramen restaurant based on a design found on dribbble.com. It includes a simple navigation bar, an intro section, a menu and basic shopping cart, a specials section with custom carousel, and contact area.",
    roles: ["Coding"],
    techCategories: [
      { title: "Core", technologies: ["React", "JavaScript"] },
      {
        title: "React Modules",
        technologies: ["Styled-Components", "React Router DOM"]
      },
      {
        title: "Applications",
        technologies: ["Adobe Xd", "Adobe Illustrator", "VS Code"]
      },
      { title: "Misc", technologies: ["GIT"] }
    ],
    screenshotUrls: ["full.png", "menu.png", "specials.png"]
  },
  {
    year: 2019,
    types: ["web", "design"],
    title: "Portfolio",
    subTitle: "The very same portfolio that you're on!",
    description:
      "I've updated my portfolio to be more in-line with today's web standards. I went for a minimalistic approach in order to convey who I am and what I do without any unneccessary clutter. Obvious features include home, about me, projetcs, and contact me pages, as well as full scalability across device screens as well as mobile support.",
    roles: ["Design", "Coding"],
    techCategories: [
      { title: "Core", technologies: ["React", "JavaScript"] },
      {
        title: "React Modules",
        technologies: ["Styled-Components", "React Router DOM"]
      },
      {
        title: "Applications",
        technologies: ["Adobe Xd", "Adobe Illustrator", "VS Code"]
      },
      { title: "Misc", technologies: ["GIT"] }
    ],
    screenshotUrls: ["home.png", "about.png", "projects.png", "contact.png"]
  },
  {
    year: 2019,
    types: ["web", "design"],
    title: "Credit Card Creator",
    subTitle: "A randomised credit card generator",
    description:
      "A site that generates an infinite number of credit cards. Each element is random including gradients. Gradients are made by randomly building a starting colour hex and inverting it to receive the second colour. This is what makes them contrasting. All names and surnames are retrieved through an API. Numbers and expiry dates are random, as well as whether it’s a visa or mastercard. Disclaimer: This is just for fun and the cards are obviously not valid or meant for use.",
    roles: ["Design", "Coding"],
    techCategories: [
      { title: "Core", technologies: ["React", "JavaScript"] },
      { title: "React Modules", technologies: ["Styled-Components"] },
      {
        title: "Applications",
        technologies: ["Adobe Xd", "Adobe Illustrator", "VS Code"]
      },
      { title: "Misc", technologies: ["GIT"] }
    ],
    screenshotUrls: ["full.png", "card.png"]
  },
  {
    year: 2019,
    types: ["web", "design"],
    title: "Holiday Photo Storage",
    subTitle:
      "A site dedicated to storing and sharing your best holiday memories.",
    description:
      "A semi-private single page site making use of multiple transition pages for storage of trip photos. The user may search for a country, town, or date, as well as sort directly by town or show an overview of all their trips. Each photo can have a description attached to it as well as have the ability to show which users are tagged in it. Photos can be viewed in card or tile views.",
    roles: ["Design", "Coding"],
    techCategories: [
      { title: "Core", technologies: ["React", "JavaScript"] },
      { title: "React Modules", technologies: ["Styled-Components"] },
      {
        title: "Applications",
        technologies: ["Adobe Xd", "Adobe Illustrator", "VS Code"]
      },
      { title: "Misc", technologies: ["GIT"] }
    ],
    screenshotUrls: ["full.png", "tile view.png", "add view.png"]
  }

  // {
  //   year: 2019,
  //   type: "web",
  //   title: "Holiday Photo Storage Site",
  //   subTitle:
  //     "A site dedicated to storing and sharing your best holiday memories.",
  //   description:
  //     "lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum ",
  //   roles: ["Design", "Coding"],
  //   technologies: [
  //     { title: "Core", technologies: ["React", "JavaScript"] },
  //     { title: "React Modules", technologies: ["Styled-Components"] },
  //     {
  //       title: "Applications",
  //       technologies: ["Adobe Xd", "Adobe Illustrator", "VS Code"]
  //     },
  //     { title: "Misc", technologies: ["GIT"] }
  //   ]
  // }
];

const App = props => {
  const [animateHeaderScroll, setAnimateHeaderScroll] = useState(true);

  useEffect(() => {
    let lastScrollTopValue =
      window.pageYOffset || document.documentElement.scrollTop;

    window.addEventListener("scroll", () => {
      let st = window.pageYOffset || document.documentElement.scrollTop;

      setAnimateHeaderScroll(st <= lastScrollTopValue);

      lastScrollTopValue = st <= 0 ? 0 : st;
    });

    return () => window.removeEventListener("scroll");
  }, []);

  const getProjectById = id =>
    projects.find(
      x => x.title.toLowerCase().replace(new RegExp(" ", "g"), "-") === id
    );

  return (
    <AppStyle {...props}>
      <Router>
        <Header
          {...(animateHeaderScroll ? { animate: "show" } : { animate: "hide" })}
        />
        <Content>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={AboutAndSkills} />
            <Route
              path="/(projects|projects/)"
              exact
              render={() => <Projects projects={projects} />}
            />
            <Route path="/contact" exact component={ContactMe} />
            <Route
              path="/projects/:id"
              exact
              render={props => (
                <Project
                  {...props}
                  project={getProjectById(props.match.params.id)}
                />
              )}
            />
            <Route component={NotFound} />
          </Switch>
        </Content>
      </Router>
    </AppStyle>
  );
};

export default App;
