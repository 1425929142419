import React, { useEffect, useReducer } from "react";
import Styled, { keyframes, css } from "styled-components";

import * as Style from "../ComponentStyles.js";
import { Link } from "react-router-dom";

const Container = Styled.div`
  padding: 80px 16px 0;

  height: calc(100% - 80px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  animation: ${Style.LoadAnim} 0.5s ease-in;
`;

const Anim = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(128px);
  }
`;

const CentralContainer = Styled.div`
  width: 100%;
  max-width: 1440px;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* animation: ${Anim} 1s ease-in;
  animation-delay: 3s;
  animation-fill-mode: forwards; */
`;

const TitleContainer = Styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Title = Styled.h1`
@import url('https://fonts.googleapis.com/css?family=Fredericka+the+Great&display=swap');
  font-family: 'Fredericka the Great', cursive;

  font-size: 20vw;
  user-select: none;
  height: 22vw;
  margin-bottom: 64px;

  @media (min-width: 1440px) {
    font-size: 260px;
    height: 288px;
  }

  font-weight: 400;

  color: transparent;

  background: url("/images/backgrounds/dither_${props => props.ditherId}.png");
  
  @media (max-width: 500px) {
    background-size: 4px;

    margin-bottom: 32px;
  }
  
  background-clip: text;
  -webkit-background-clip: text;
`;

const SubTitle = Styled.h2`
  font-size: 5vw;
  font-weight: 600;

  margin-bottom: 32px;

  @media (min-width: 1440px) {
    font-size: 60px;
  }
`;

const Text = Styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 2;

  ${props =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

const Links = Styled.div`
  display: flex;
  justify-content: space-around;
  width: 50vw;

  max-width: 500px;

  margin-top: 64px;

  @media (max-width: 650px) {
    /* height: 100%; */
    margin-top: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${Style.Button} {
      margin-bottom: 16px;
    }
  }
`;

const getRandNum = (min, max) => {
  return Math.random() * (max - min) + min;
};

const buttons = [
  // { title: "About Me", href: "/about" },
  // { title: "My Projects", href: "/projects" },
  { title: "Get a brand new website", href: "https://www.damant.global"},
  { title: "Contact Me", href: "/contact" }
];

const words = ["concept", "design", "develop", "deploy"];

const initialTypingState = {
  timer: undefined,
  typingState: "typing",
  delay: 300,
  currentWordIndex: 0,
  currentText: ""
};

const typingReducer = (state, action) => {
  switch (action.type) {
    case "beginTyping": {
      return {
        ...state,
        typingState: "typing",
        currentWordIndex:
          state.currentWordIndex + 1 >= words.length
            ? 0
            : state.currentWordIndex + 1,
        delay: 200
      };
    }
    case "beginDeleting": {
      return {
        ...state,
        typingState: "deleting",
        delay: 2000
      };
    }
    case "type": {
      return {
        ...state,
        currentText: words[state.currentWordIndex].substring(
          0,
          state.currentText.length + 1
        ),
        delay: getRandNum(50, 100)
      };
    }
    case "delete": {
      return {
        ...state,
        typingState: "deleting",
        currentText: words[state.currentWordIndex].substring(
          0,
          state.currentText.length - 1
        ),
        delay: 60
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

const Button = ({ href, children }) => {
  if (href.indexOf("https://") > -1) {
    return <a href={href}>
      <Style.Button primary>
        <p>{children}</p>
      </Style.Button>
    </a>
  } else {
    return <Link
    to={href}
    onClick={() => {
      window.scrollTo(0, 0);
    }}
  >
    <Style.Button>
      <p>{children}</p>
    </Style.Button>
  </Link>
  }
}

const Home = () => {
  const [state, dispatch] = useReducer(typingReducer, initialTypingState);

  useEffect(() => {
    setTimeout(() => {
      // If it's currently typing
      if (state.typingState === "typing") {
        // If we've reached the end of the word
        if (state.currentText === words[state.currentWordIndex]) {
          dispatch({ type: "beginDeleting" });
        } // If we still haven't reached the end of the word
        else {
          // Keep on typing.
          dispatch({ type: "type" });
        }
      } // Otherwise, if we are currently deleting
      else if (state.typingState === "deleting") {
        // If we've reached an empty string
        if (state.currentText === "") {
          dispatch({ type: "beginTyping" });
        } // If we still haven't reached an empty string
        else {
          // Keep on deleting.
          dispatch({ type: "delete" });
        }
      }
    }, state.delay);
  }, [state]);

  return (
    <Container>
      <CentralContainer>
        <TitleContainer>
          <Title
            data-text={state.currentText}
            ditherId={state.currentWordIndex + 1}
            delay="2"
          >
            {state.currentText === "" ? `.` : state.currentText}
          </Title>
        </TitleContainer>

        <SubTitle>It starts with an idea.</SubTitle>
        <Text>
          Professional web development and design amongst other things.
        </Text>
        <Text bold>Stefan Cauchi</Text>
        <Links>
          {buttons.map(({ href, title }, ind) => (
            <Button href={href}>{title}</Button>
          ))}
        </Links>
      </CentralContainer>
    </Container>
  );
};

export default Home;
