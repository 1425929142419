import React from "react";
import Styled from "styled-components";

import * as Style from "../ComponentStyles.js";

import NotFound from "../components/NotFound";

const Container = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 16px;

  animation: ${Style.LoadAnim} 0.25s ease-in;
`;

const OpeningImageContainer = Styled.div`
  width: calc(100% + 32px);

  background: url(${props => props.openingImageUrl}) no-repeat;
  background-position: 50%;

  margin-bottom: 32px;

  @media (max-width: 1000px) {
    height: 500px;
    background-size: auto 100%;
  }

  @media (min-width: 1001px) and (max-width: 1185px) {
    height: 50vh;
    background-size: auto 100%;
  }

  @media (min-width: 1186px) {
    height: 50vh;
    background-size: 100% auto;
  }
`;

const InfoContainer = Styled.div`
  display: flex;
  flex-direction: row;

  max-width: 1440px;
  margin-bottom: 32px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

const DetailContainer = Styled.section`
  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (min-width: 1001px) {
    width: 50%;
  }
`;

const TechContainer = Styled.section`
  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (min-width: 1001px) {
    width: 50%;
  }
`;

const ImagesContainer = Styled.section`
  width: 100%;
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
    margin-bottom: 16px;
  }
`;

const ImagesTitle = Styled(Style.Title)`
  margin-right: auto;
`;

const ImagesSubTitle = Styled(Style.SubTitle)`
  margin-right: auto;
`;

const getScreenshotBasePath = title => {
  return `/images/projects/${title
    .toLowerCase()
    .replace(new RegExp(" ", "g"), "-")}/`;
};

const Project = props => {
  if (props.project === undefined) {
    return <NotFound />;
  } else {
    document.title = props.project.title + " | Stefan Cauchi";
    return (
      <Container>
        <OpeningImageContainer
          openingImageUrl={`${getScreenshotBasePath(
            props.project.title
          )}banner.png`}
        />
        <InfoContainer>
          <DetailContainer>
            <Style.Title>{props.project.title}</Style.Title>
            <Style.SubTitle>{props.project.subTitle}</Style.SubTitle>
            <Style.Text>{props.project.description}</Style.Text>
          </DetailContainer>
          <TechContainer>
            <Style.Title right>Tech Used</Style.Title>

            <Style.SubTitle right>
              Tech used in making this project.
            </Style.SubTitle>
            {props.project.techCategories.map((cat, ind) => (
              <Style.List right key={ind}>
                <Style.ListTitle>{cat.title}</Style.ListTitle>
                {cat.technologies.map((tech, ind) => (
                  <Style.ListItem key={ind}>{tech}</Style.ListItem>
                ))}
              </Style.List>
            ))}
          </TechContainer>
        </InfoContainer>

        <ImagesContainer>
          <ImagesTitle>Images</ImagesTitle>
          <ImagesSubTitle>A few shots of the project.</ImagesSubTitle>
          {props.project.screenshotUrls.map((url, ind) => (
            <img
              key={ind}
              src={getScreenshotBasePath(props.project.title) + url}
              alt="Screenshot"
            />
          ))}
        </ImagesContainer>
      </Container>
    );
  }
};

export default Project;
